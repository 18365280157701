<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="我的课表"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <el-calendar v-model="value">
      <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
      <template slot="dateCell" slot-scope="{ data }">
        <!-- 
          :class="{
            toDaty: data.day == getToDay() && isFirst,
            chooseDay: data.day == is_chooseDay ? 'chooseDay' : '',
          }" -->
        <div
          class="days"
          @click="curDayCourse(data.day)"
          :class="{
            toDaty: data.day == getToDay() && isFirst,
            chooseDay: data.day == is_chooseDay ? 'chooseDay' : '',
          }"
        >
          <span>{{ data.day.split("-")[2] }}</span>
          <span
            v-if="haveCourse(data.day)"
            style="color: #dd524d; font-size: 12px"
            >有课</span
          >
          <span style="font-size: 12px" v-else>{{
            solarDate2lunar(data.day).split(",")[1] == "初一"
              ? solarDate2lunar(data.day).split(",")[0]
              : solarDate2lunar(data.day).split(",")[1]
          }}</span>
          <div class="redpoint" v-if="haveCourse(data.day)"></div>
        </div>
      </template>
    </el-calendar>
    <el-scrollbar
      class=""
      style="height: calc(100vh - 431px); background: #fff"
      :native="false"
      id="resultScroll"
      ref="myScrollbar"
    >
      <div style="padding: 0 15px 20px">
        <div class="toDayTitle">今日课程</div>
        <div v-if="list.length > 0">
          <div v-for="item in list" :key="item.Id" class="courseBox">
            <div
              style="
                font-size: 15px;
                font-weight: bold;
                color: #333333;
                line-height: 36px;
              "
            >
              {{ item.CourseName }}
            </div>
            <div class="info">
              时间：{{ item.StartTime.split(" ")[0] }}
              {{ item.StartTime.split(" ")[1] }} ~
              {{ item.EndTime.split(" ")[1] }}
            </div>
            <div style="display: flex; justify-content: space-between">
              <div>
                <div class="info">讲师：{{ item.TeacherName }}</div>
                <div class="info decimalPoint" style="width: 60vw">
                  内容：{{ item.Remark }}
                </div>
                <div class="info">
                  地点：{{ item.ClassRoomName
                  }}<span v-if="item.IsLive == 1">+线上直播</span>
                </div>
              </div>
              <div>
                <!-- <el-button
                type="primary"
                size="mini"
                v-if="item.IsSign == 1 && getToDay()==item.OpenDate.split(' ')[0]"
                @click="studentSign(item)"
                >签到</el-button
              > -->
                <el-button
                  type="primary"
                  size="mini"
                  v-if="item.IsSign == 0"
                  @click="studentSign(item)"
                  >签到</el-button
                >
                <span v-else-if="item.IsSign == 1">已签到</span>
              </div>
            </div>
            <div class="current" v-if="item.IsSign == 1">
              已签到{{ item.CurrentStudentSignCount }}次
            </div>
          </div>
        </div>
        <div
          v-if="pageIndex > pageCount && list.length > 0"
          style="
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #969494;
          "
        >
          ······ 到底啦 ······
        </div>
        <div v-if="list.length <= 0">
          <no-data></no-data>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import calendar from "@/utils/calendar";
import noData from "@/components/noData";
import moment from "moment"; //导入模块
import {
  QueryStudentCoursePlanList,
  QueryCoursePlanCount,
  studentSignByTable,
} from "@/api/personal";
export default {
  data() {
    return {
      list: [],
      isFirst: true, //刚进来默认给样式当前日期
      pageIndex: 1,
      pageSize: 100,
      pageCount: 0,
      total: 0,
      startTime: "",
      endTime: "",
      openCount: [], //有课的数组
      studentId: 0,
      value: "",
      startDay: "",
      endDay: "",
      is_chooseDay: "",
      isWxApplets: true,
    };
  },
  components: {
    noData,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "我的课表";
      this.isWxApplets = false;
    }
    this.studentId = this.$route.query.studentId;
    var nowDate = new Date();
    var fullYear = nowDate.getFullYear();
    var month = nowDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月
    var endOfMonth = new Date(fullYear, month, 0).getDate(); // 获取本月最后一天
    this.startTime = this.getFullDate(nowDate.setDate(1)); //当月第一天
    this.endTime = this.getFullDate(nowDate.setDate(endOfMonth)); //当月最后一天
    this.startDay = this.getToDay();
    this.endDay = this.getToDay();
    // this.value = this.getToDay();
    this.getCourseList();
    this.getCourse();
  },
  mounted() {
    var that = this;
    // 监听滚动事件
    document
      .getElementById("resultScroll")
      .addEventListener("scroll", that.handleScroll, true);
    this.$nextTick(() => {
      // 点击前一个月
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(1)"
      );
      prevBtn.addEventListener("click", () => {
        this.goMonth(this.value);
      });
    });
    this.$nextTick(() => {
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(2)"
      );
      prevBtn.addEventListener("click", () => {
        if (this.value) {
          this.goMonth(this.value, 0);
          this.isFirst = true;
        } else {
          this.list = [];
          this.startDay = this.getToDay();
          this.endDay = this.getToDay();
          this.getCourse();
          this.isFirst = true;
        }
      });
    });
    this.$nextTick(() => {
      // 点击后一个月
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:last-child"
      );
      prevBtn.addEventListener("click", () => {
        this.goMonth(this.value);
      });
    });
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    //上个月，下个月
    goMonth(value, num) {
      this.is_chooseDay = "";
      let year = new Date(value).getFullYear();
      let month = new Date(value).getMonth() + 1;
      let day = new Date(value).getDate();
      let item = year + "-" + month + "-" + day;
      this.startTime = this.getMonth("s", year, month);
      this.endTime = this.getMonth("e", year, month);
      this.startDay = this.getMonth("s", year, month);
      this.endDay = this.getMonth("e", year, month);
      this.isFirst = true;
      // this.list = [];
      this.openCount = [];
      this.getCourseList();
      if (num == 0) {
        this.curDayCourse(item);
      } else {
        // this.getCourse();
      }
    },
    getMonth(type, year, month) {
      //getMonth(type,year,months)  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束
      //getMonth("s",2020,5)  //得到该月第一天的yyyy-mm-dd格式日期
      //getMonth("e",2020,5)  //得到该月最后一天的yyyy-mm-dd格式日期
      month = month < 10 ? "0" + month : month;
      var firstday = year + "-" + month + "-" + "01";
      var lastday = "";
      if (
        month == "01" ||
        month == "03" ||
        month == "05" ||
        month == "07" ||
        month == "08" ||
        month == "10" ||
        month == "12"
      ) {
        lastday = year + "-" + month + "-" + 31;
      } else if (month == "02") {
        if (
          (year % 4 == 0 && year % 100 != 0) ||
          (year % 100 == 0 && year % 400 == 0)
        ) {
          lastday = year + "-" + month + "-" + 29;
        } else {
          lastday = year + "-" + month + "-" + 28;
        }
      } else {
        lastday = year + "-" + month + "-" + 30;
      }
      var day = "";
      if (type == "s") {
        day = firstday;
      } else {
        day = lastday;
      }
      return day;
    },
    //是否有课
    haveCourse(day) {
      return this.openCount.findIndex((item) => {
        return day == item;
      }) != -1
        ? true
        : false;
    },
    //日期
    getFullDate(targetDate) {
      var D, y, m, d;
      if (targetDate) {
        D = new Date(targetDate);
        y = D.getFullYear();
        m = D.getMonth() + 1;
        d = D.getDate();
      } else {
        y = fullYear;
        m = month;
        d = date;
      }
      m = m > 9 ? m : "0" + m;
      d = d > 9 ? d : "0" + d;
      return y + "-" + m + "-" + d;
    },
    //显示当前时间（年月日时分秒）
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      return year + "-" + month + "-" + date + " " + hh + ":" + mm + ":" + ss;
    },
    nowTimes() {
      this.timeFormate(new Date());
    },
    //获取月是否有课程
    async getCourseList() {
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
        studentId: this.studentId,
      };
      const res = await QueryCoursePlanCount(data);
      if (res.success === true) {
        if (res.response) {
          res.response.forEach((item, index) => {
            if (item.PlanCount > 0) {
              this.openCount.push(item.OpenDate.split(" ")[0]);
              // console.log(item.OpenDate, index);
            }
          });
        }
        // console.log(this.openCount);
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取课程
    async getCourse() {
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        startTime: this.startDay,
        endTime: this.endDay,
        studentId: this.studentId,
      };
      const res = await QueryStudentCoursePlanList(data);
      if (res.success === true) {
        if (this.isFirst) {
          res.response.data.forEach((item) => {
            if (item.OpenDate) {
              this.openCount.push(item.OpenDate.split(" ")[0]);
            }
          });
        }
        this.list = this.list.concat(res.response.data);
        this.total = res.response.dataCount;
        this.pageCount = res.response.pageCount;
      } else {
        this.$message.error(res.msg);
      }
    },
    async studentSign(item) {
      // console.log(item, '')
      // console.log(new Date().getTime(), '当前')
      // console.log(new Date(item.EndTime.replace(/-/g, '/')).getTime(), '结束')
      // console.log(new Date(item.StartTime.replace(/-/g, '/')).getTime() - 30 * 60 * 1000, '开始前半个小时')
      // console.log(new Date(item.StartTime.replace(/-/g, '/')).getTime(), '开始时间')
      // return
      if (
        new Date().getTime() >
          new Date(item.EndTime.replace(/-/g, "/")).getTime() ||
        new Date().getTime() <
          new Date(item.StartTime.replace(/-/g, "/")).getTime() - 30 * 60 * 1000
      ) {
        this.$message.error("当前时段不能签到");
        return false;
      }
      let data = {
        StudentId: this.studentId,
        CourseId: item.CourseId,
        PlanId: item.Id,
      };
      // console.log(data);
      const res = await studentSignByTable(data);
      if (res.success === true) {
        this.$message.success("签到成功");
        this.getCourse();
        item.IsSign = 1;
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取当前日期
    getToDay() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    //滚动事件
    handleScroll() {
      var that = this;
      var sh = that.$refs["myScrollbar"].$refs["wrap"].scrollHeight; // 滚动条高度
      var st = that.$refs["myScrollbar"].$refs["wrap"].scrollTop; // 滚动条距离顶部的距离
      var ch = that.$refs["myScrollbar"].$refs["wrap"].clientHeight; // 滚动条外容器的高度
      if (st + ch >= sh) {
        //到底了-业务逻辑
        // that.pageIndex += 1;
        // if (that.pageIndex <= that.pageCount) {
        //   that.getCourse();
        // }
      }
    },
    //插件js转换农历
    solarDate2lunar(solarDate) {
      var solar = solarDate.split("-");
      var lunar = calendar.solar2lunar(solar[0], solar[1], solar[2]);
      return lunar.IMonthCn + "," + lunar.IDayCn;
    },
    //每一天的点击事件
    curDayCourse(item) {
      // console.log(this.value, "");
      // console.log(item, "");
      this.is_chooseDay = item;
      this.list = [];
      this.isFirst = false;
      this.startDay = item;
      this.endDay = item;
      this.getCourse();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-calendar-table .el-calendar-day {
  padding: 0px;
  height: 45px;
}
/deep/ .el-calendar-table td.is-selected {
  background-color: #fff;
}
/deep/ .el-calendar-table thead th {
  text-align: center !important;
}
/deep/ .el-calendar-table .el-calendar-day:hover {
  // color: #fff;
  // opacity: 0.8;
  // background: #007aff;
  background-color: transparent;
}
/deep/ .el-calendar__body {
  padding: 12px 20px 0px;
}
.days {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .redpoint {
    width: 5px;
    height: 5px;
    // background: red;
    background: #f1c800;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
.toDayTitle {
  font-size: 17px;
  font-weight: bold;
  color: #1d1d1d;
  line-height: 16px;
  margin-top: 20px;
}
.courseBox {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  padding: 15px;
  position: relative;
  .current {
    border-radius: 0px 6px 0px 6px;
    min-width: 58px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    background: #2dd6b1;
    padding: 0 5px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    transform: scale(0.9);
  }
}
.toDaty {
  color: #fff;
  opacity: 0.8;
  // background: #007aff;
  background: #f1c800;
}
.info {
  font-size: 12px;
  font-weight: 500;
  color: #888888;
  line-height: 21px;
}
.chooseDay {
  color: #fff;
  opacity: 0.8;
  // background: #007aff;
  background: #f1c800;
  .redpoint {
    width: 5px;
    height: 5px;
    // background: red;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
